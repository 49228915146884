import type { ButtonProps, ButtonVariantProps } from './types';
import { BUTTON_VARIANTS } from './constants';

export const getVariant =
  (propName: ButtonVariantProps) =>
  <T extends ButtonProps>({ variant }: T): string => {
    const variantProps = BUTTON_VARIANTS[variant!] || BUTTON_VARIANTS.default;

    return variantProps[propName] || '';
  };
