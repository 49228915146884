import LinkedinIcon from '@assets/images/linkedin.svg';
import CompanyLogoImage from '@assets/images/logo.svg';
import AndroidLogoImage from '@assets/images/android.svg';

import {
  FooterContainer,
  SocialDescription,
  SocialLink,
  ContainerIcons,
} from './styles';

export const Footer = () => (
  <FooterContainer>
    <img src={CompanyLogoImage} alt="Sales Sense" height={42} />
    <ContainerIcons>
      <SocialLink
        download
        href="https://s3.us-east-2.amazonaws.com/download-app.salessense.com.br/app-release.apk"
      >
        <img
          src={AndroidLogoImage}
          width={32}
          height={32}
          alt="Baixe o aplicativo"
        />
      </SocialLink>

      <SocialLink
        href="https://www.linkedin.com/company/salessensebr"
        target="_blank"
        download
      >
        <img
          src={LinkedinIcon}
          width={32}
          height={32}
          alt="Acompanhe no linkedin"
        />
      </SocialLink>
    </ContainerIcons>
  </FooterContainer>
);
