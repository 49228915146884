import { PALETTE } from '@core/constants';

export const BUTTON_VARIANTS: any = {
  solid: {
    color: PALETTE.white,
    weight: 'bold',
    background: PALETTE.primary,
    backgroundHover: PALETTE.dodgerBlue,
    border: null,
  },
  outline: {
    color: PALETTE.primary,
    colorHover: PALETTE.dodgerBlue,
    backgroundHover: PALETTE.hawkesBlue,
    weight: 'normal',
    background: 'transparent',
    border: '1px solid currentColor',
  },
  ghost: {
    color: PALETTE.mineShaft,
    weight: 'normal',
    background: 'transparent',
    border: null,
  },
  get default() {
    return this.solid;
  },
};
