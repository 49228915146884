import { ContactModalContext } from '@core/contexts/ContactModalContext';
import { useContext } from 'react';

export const useContactModal = () => {
  const state = useContext(ContactModalContext);

  if (!state) {
    throw Error('Contact modal context not found');
  }

  return state;
};
