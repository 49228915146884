import styled from 'styled-components';
import { BREAKPOINTS } from '@core/constants/breakpoints';

export const Container = styled.section`
  padding: 24px;
  max-width: var(--site-width);
  margin: 0 auto;

  @media ${BREAKPOINTS.tablet} {
    padding: 68px 100px;
    min-height: 600px;
  }
`;
