import { forwardRef } from 'react';
import { ButtonElement } from './styles';
import { ButtonProps } from './types';

export const Button = forwardRef<null, ButtonProps>(
  ({ children, ...otherProps }, ref) => (
    <ButtonElement ref={ref} {...otherProps}>
      {children}
    </ButtonElement>
  ),
);

Button.defaultProps = {
  color: 'primary',
  variant: 'solid',
};

Button.displayName = 'Button';
