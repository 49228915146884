import styled from 'styled-components';
import { BREAKPOINTS } from '@core/constants/breakpoints';
import { PALETTE } from '@core/constants';

export const HeaderContainer = styled.header`
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 22px;
  background-color: ${PALETTE.white};
  border-bottom: 1px solid ${PALETTE.mercury};

  @media ${BREAKPOINTS.desktop} {
    padding: 22px 100px;
  }
`;

export const CompanyLogo = styled.img``;

export const ButtonContainer = styled.div`
  display: none;

  @media ${BREAKPOINTS.tablet} {
    display: flex;
    gap: 38px;
    margin-left: auto;
  }
`;
