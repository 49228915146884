import styled from 'styled-components';
import { PALETTE } from '@core/constants';
import { when } from '@core/utils/styled';

export const InputText = styled.input<{ invalid?: boolean }>`
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 8px;
  height: 46px;

  ${when('invalid', `border-color: ${PALETTE.bittersweet}`)}
`;
