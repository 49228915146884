import React, { Children, cloneElement, ReactElement, ReactNode } from 'react';
import type { FormFieldProps } from './types';

import { ErrorMessage, Label, LabelText } from './styles';

export const FormField = ({ label, error, children }: FormFieldProps) => {
  return (
    <Label>
      <LabelText>{label}</LabelText>
      {Children.map(children, child =>
        cloneElement(child as ReactElement, { invalid: !!error }),
      )}
      {error && <ErrorMessage aria-alert="assertive">{error}</ErrorMessage>}
    </Label>
  );
};
