import { MdClear } from 'react-icons/md';

import { ModalCompProps } from './types';
import { StyledModal, Backdrop, CloseContent } from './styles';

const renderBackdrop = (props: any) => <Backdrop {...props} />;

export const Modal = ({
  show,
  onClose,
  width,
  height,
  minHeight,
  maxHeight,
  showCloseButton,
  className,
  children,
}: ModalCompProps) => {
  return (
    <StyledModal
      $width={width}
      $height={height}
      $minHeight={minHeight}
      $maxHeight={maxHeight}
      show={show}
      onHide={onClose}
      renderBackdrop={renderBackdrop}
      className={className}
      aria-labelledby="modal-label"
    >
      <>
        {showCloseButton && (
          <CloseContent>
            <MdClear size={24} onClick={onClose} className="cursor-pointer" />
          </CloseContent>
        )}
        {children}
      </>
    </StyledModal>
  );
};

Modal.defaultProps = {
  width: '600px',
  showCloseButton: true,
};
