import styled from 'styled-components';
import { when } from '@core/utils/styled';
import { ButtonProps } from './types';
import { getVariant } from './utils';
import { PALETTE } from '@core/constants';

export const ButtonElement = styled.button<ButtonProps>`
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: 0;
  padding: 12px 18px;
  border-radius: 4px;
  text-align: center;
  color: ${PALETTE.white};
  font-size: 16px;
  cursor: pointer;

  color: ${getVariant('color')};
  background-color: ${getVariant('background')};
  font-weight: ${getVariant('weight')};
  border: ${getVariant('border')};
  font-weight: ${when('bold', 'bold')};
  transition: background-color 0.3s, color 0.3s, transform 0.2s;

  &:focus {
    outline: 1px solid ${PALETTE.primary};
    outline-offset: 4px;
  }

  &:hover,
  &:focus {
    background-color: ${getVariant('backgroundHover')};
    color: ${getVariant('colorHover')};
  }

  &:active {
    transform: scale(0.99);
  }
`;
