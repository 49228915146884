import type { Interpolation, InterpolationValue } from 'styled-components';

export const when =
  <P extends Record<string, any>>(
    propName: string,
    valueA: Interpolation<P>,
    valueB?: Interpolation<P>,
  ): ((props: P) => Interpolation<P>) =>
  props =>
    props[propName] ? valueA : valueB;

export const whenCss =
  <P extends Record<string, any>>(
    propName: string,
    cssPropName?: string,
    valueB?: InterpolationValue,
  ): ((props: P) => Interpolation<P>) =>
  props => {
    const cssProp = cssPropName || propName;

    if (props[propName]) {
      return `${cssProp}: ${props[propName]};`;
    }

    return valueB && `${cssProp}: ${valueB};`;
  };

export const get =
  (propName: string, defaultValue?: string) =>
  <P extends Record<string, any>>(props: P): InterpolationValue =>
    props[propName] ?? defaultValue;
