import { BREAKPOINTS } from '@core/constants/breakpoints';
import { PALETTE } from '@core/constants';
import styled from 'styled-components';

export const FooterContainer = styled.footer`
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  background-color: ${PALETTE.gallery};

  @media ${BREAKPOINTS.tablet} {
    padding: 16px 150px;
  }
`;

export const SocialLink = styled.a`
  display: flex;
  align-items: center;
  border-radius: 4px;
  margin-left: 16px;
  cursor: pointer;
`;

export const SocialDescription = styled.p`
  color: ${PALETTE.mineShaft};
  font-size: 16px;
  margin-left: 22px;

  @media ${BREAKPOINTS.mobile} {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
`;

export const ContainerIcons = styled.div`
  display: flex;
`;
