import Link from 'next/link';
import { forwardRef } from 'react';
import { Button } from '../Button';
import { ButtonLinkProps } from './types';

export const ButtonLink = forwardRef<null, ButtonLinkProps>(
  ({ href, external, target, as, shallow, children, ...otherProps }, ref) => {
    const LinkTag = external ? 'a' : Link;

    return (
      <LinkTag href={href} as={as} shallow={shallow} target={target}>
        <Button ref={ref} {...otherProps} tabIndex={-1}>
          {children}
        </Button>
      </LinkTag>
    );
  },
);

ButtonLink.defaultProps = {
  color: 'primary',
  variant: 'solid',
  external: false,
  target: '',
};

ButtonLink.displayName = 'ButtonLink';
