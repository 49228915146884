import styled from 'styled-components';
import { PALETTE } from '@core/constants';

export const Label = styled.label`
  display: flex;
  flex-direction: column;
`;

export const LabelText = styled.span`
  display: block;
  margin-bottom: 4px;
  font-size: 16px;
  line-height: 23px;
`;

export const ErrorMessage = styled.p`
  color: ${PALETTE.bittersweet};
`;
