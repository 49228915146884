import CompanyLogoImage from '@assets/images/logo.svg';
import { useContactModal } from '@core/hooks/useContactModal';
import { Button } from '..';

import { ButtonLink } from '../ButtonLink';

import { HeaderContainer, CompanyLogo, ButtonContainer } from './styles';

export const Header = () => {
  const { openModal } = useContactModal();
  return (
    <HeaderContainer>
      <CompanyLogo
        src={CompanyLogoImage}
        alt="Sales Sense"
        width={220}
        height={56}
      />

      <ButtonContainer>
        <Button variant="ghost" onClick={openModal}>
          Contato
        </Button>

        <ButtonLink
          href={process.env.NEXT_PUBLIC_SIGN_IN_PATH!}
          target="_blank"
          variant="outline"
          external
        >
          Acessar minha conta
        </ButtonLink>
      </ButtonContainer>
    </HeaderContainer>
  );
};
