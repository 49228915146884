import styled from 'styled-components';
import Modal from 'react-overlays/Modal';

import { get } from '@core/utils/styled';
import { ModalCompProps } from './types';
import { PALETTE } from '@core/constants';

export const Backdrop = styled.div`
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${PALETTE.black};
  opacity: 0.5;
`;

export const StyledModal = styled(Modal)<ModalCompProps>`
  position: fixed;
  display: flex;
  flex-direction: column;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: ${get('$maxHeight', '95%')};
  min-height: ${get('$minHeight', 'initial')};
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  z-index: 1040;

  height: ${get('$height')};
  width: ${get('$width')};
`;

export const CloseContent = styled.button`
  position: absolute;
  right: 16px;
  top: 16px;
  background-color: transparent;
  border: 0;
  appearance: none;
  cursor: pointer;
  color: white;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
